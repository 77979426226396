<template>
  <div class="custom-form">
    <div class="panel panel-primary">
      <div class="panel-heading">
        <h3 class="panel-title">SEND US YOUR ENQUIRY / BOOK FOR VIEWING</h3>
      </div>
      <div class="panel-body">
        <div
          class="form-group"
          v-bind:class="{ 'has-error': errors.has('enq_PersonName') }"
        >
          <label for="enq_PersonName">Your name</label>
          <input
            id="enq_PersonName"
            name="enq_PersonName"
            v-model.trim="fdata.enq_PersonName"
            v-validate.initial="{ required: true, max: 100 }"
            maxlength="100"
            class="form-control"
          />
          <span class="help-block">{{ errors.first("enq_PersonName") }}</span>
        </div>

        <div
          class="form-group"
          v-bind:class="{ 'has-error': errors.has('enq_Mobile') }"
        >
          <label for="enq_Mobile">Mobile / telephone number</label>
          <input
            id="enq_Mobile"
            name="enq_Mobile"
            v-model.trim="fdata.enq_Mobile"
            v-validate.initial="{ required: true, max: 100 }"
            maxlength="100"
            class="form-control"
          />
          <span class="help-block">{{ errors.first("enq_Mobile") }}</span>
        </div>

        <div
          class="form-group"
          v-bind:class="{ 'has-error': errors.has('enq_Email') }"
        >
          <label for="enq_Email">E-mail</label>
          <input
            id="enq_Email"
            name="enq_Email"
            v-model.trim="fdata.enq_Email"
            v-validate="{ max: 100, email: true }"
            maxlength="100"
            class="form-control"
          />
          <span class="help-block">{{ errors.first("enq_Email") }}</span>
        </div>

        <div
          class="form-group"
          v-bind:class="{ 'has-error': errors.has('enq_Message') }"
        >
          <label for="enq_Message">Your enquiry</label>
          <textarea
            id="enq_Message"
            name="enq_Message"
            v-model.trim="fdata.enq_Message"
            v-validate="{ max: 500 }"
            maxlength="500"
            rows="4"
            class="form-control"
          ></textarea>
          <span class="help-block">{{ errors.first("enq_Message") }}</span>
        </div>

        <div class="vue-recaptcha-wrapper">
          <vue-recaptcha
            ref="enq_recaptcha"
            id="enq_recaptcha"
            v-on:verify="verifyRecaptcha"
            sitekey="6LcZOc4ZAAAAAF9H52cuyXRl3hXEKPILrAE3DMID"
          ></vue-recaptcha>
        </div>
        <modal
          name="alert-dialog2"
          :width="400"
          :height="'auto'"
          :clickToClose="false"
        >
          <div class="panel-primary">
            <div class="panel-heading modal-alert-heading">
              <span class="modal-alert-title">Information</span>
              <button
                @click="closeAlertDialog"
                type="button"
                class="close pull-right"
              >
                <span
                  class="fa fa-times-circle modal-alert-close-button-icon"
                ></span>
              </button>
            </div>
            <div class="panel-body modal-alert-body">
              <div class="modal-alert-text-wrapper">
                <span class="fa fa-exclamation-circle modal-alert-icon"></span>
                <span class="modal-alert-text">{{ alertMessage }}</span>
              </div>
              <div class="modal-alert-footer">
                <button
                  @click="closeAlertDialog"
                  type="button"
                  class="btn btn-success"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </modal>

        <div class="btn-submit-wrapper">
          <div v-if="showAnimation" class="app-animation">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
            <br />
            <span>Form submission in progress</span>
          </div>
          <div class="app-submit-btn-wrapper">
            <button
              v-on:click="submitEnquiry"
              v-bind:disabled="disableStatus_enq_recaptcha || showAnimation"
              type="submit"
              class="btn btn-common"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";

export default {
  components: {
    VueRecaptcha
  },
  data() {
    return {
      fdata: {
        enq_PersonName: "",
        enq_Mobile: "",
        enq_Email: "",
        enq_Message: "",
        enq_RecaptchaResponse: ""
      },
      showAnimation: false,
      alertMessage: ""
    };
  },
  methods: {
    verifyRecaptcha: function(res) {
      if (res) {
        this.fdata.enq_RecaptchaResponse = res;
      }
    },
    submitEnquiry: function() {
      let self = this;
      this.$validator.validateAll().then(function(result) {
        if (!result || !self.fdata.enq_RecaptchaResponse) {
          self.alertMessage = "Form contains validation warnings";
          self.$modal.show("alert-dialog2");
        } else {
          self.showAnimation = true;
          axios
            .post("Home/SubmitEnquiry", self.fdata)
            .then(r => {
              if (r.data == 0) {
                //successful
                Object.assign(self.fdata, {
                  enq_PersonName: "",
                  enq_Mobile: "",
                  enq_Email: "",
                  enq_Message: "",
                  enq_RecaptchaResponse: ""
                });
                self.$refs.enq_recaptcha.reset();
                self.alertMessage = "Your form has been submitted successfully";
                self.$modal.show("alert-dialog2");
              } else {
                //form is invalid
                self.alertMessage = "Form contains validation warnings";
                self.$modal.show("alert-dialog2");
              }
              self.showAnimation = false;
            })
            .catch(() => {
              self.showAnimation = false;
            });
        }
      });
    },
    closeAlertDialog: function() {
      this.$modal.hide("alert-dialog2");
    }
  },
  computed: {
    disableStatus_enq_recaptcha: function() {
      if (this.fdata.enq_RecaptchaResponse) {
        return false;
      }
      return true;
    }
  }
};
</script>
